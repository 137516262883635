import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

export interface GetTransactionGetMemberListOfDepositMoneyRepsonse {
  ID?: number;
  CreatedAt?: string;
  UpdatedAt?: string;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
  MemberID?: number;
  StaffID?: number | null;
  TransactionTypeID?: number;
  Uncalculated?: boolean;
  GamesID?: number;
  AfterBetAmount?: number;
  Amount?: number;
  CurrencyID?: number;
  IPAddress?: string;
  HashKey?: string;
  CurrentMemberBalance?: number;
  Reject?: boolean;
  MemberCreatedAt?: Date;
  MemberUpdatedAt?: Date;
  DisplayMemberCreatedAt?: string;
  DisplayMemberUpdatedAt?: string;
  BankNumber?: string;
  BankName?: string;
  Context?: string;
  SMPoint?: number;
  Username?: string;
  BankAccount?: string;
  TransactionTypeName?: string;
  GameNameEN?: string;
  GameNameTH?: string;
  GameSymbol?: string;
  CurrencyName?: string;
  CurrencyCountry?: string;
  BMBankNumber?: string;
  BMBankName?: string;
  BMBankAccount?: string;
  BMBankColor?: string;
  BMBankImage?: string;
  TagetBank?: TagetBank;
  TbvBankType?: string;
  TbvBankName?: string;
  TbvBankNum?: string;
  TbvType?: string;
  TbvImage?: string;
  TbvColor?: string;
  FullPayment?: number;
  Rate?: number;
  Mode?: string;
}

export interface TagetBank {
  VenderID?: number;
  Username?: string;
  BankNumber?: string;
  BankName?: string;
  Image?: string;
  Color?: string;
  BankAccount?: string;
  VenderIDBankAccount?: number;
  BTBankTypeID?: number;
  ActiveDay?: number;
  ActiveDayMember?: number;
}

export default function API(): Promise<
  GetTransactionGetMemberListOfDepositMoneyRepsonse[]
> {
  return client
    .get(`payment/get_transaction_get_member_list_of_deposit_money`)
    .then((response) => response.data)
    .then((json: GetTransactionGetMemberListOfDepositMoneyRepsonse[]) => {
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_TRANSACTION_LIST_DEPOSIT,
        payload: json,
      });
      return json;
    });
}
